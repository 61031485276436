import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './NavMenu';
import SubscriptionPrompt from './SubscriptionPrompt';
import PreferencesTable from './PreferencesTable';
import SubscriptionStatusCard from "./SubscriptionStatusCard";
import MatchedListingsTable from "./MatchedListingsTable";
import AddOrEditPreferenceModal from "./AddOrEditPreferenceModal";
import { Spinner, Alert, Pagination, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import CustomPagination from "./CustomPagination";
import '../custom.css';

const Dashboard = () => {
    const [preferences, setPreferences] = useState([]);
    const [matchedListings, setMatchedListings] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState({
        PriceRange: [],
        PropertyType: [],
        DistanceRadius: [],
        Bedrooms: []
    });
    const [newPreference, setNewPreference] = useState({
        city: '',
        priceRangeMin: '',
        priceRangeMax: '',
        propertyType: '',
        distanceRadius: '',
        minBedrooms: '',
        maxBedrooms: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingPreferenceId, setEditingPreferenceId] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage] = useState(20);
    const [preferenceLoaded, setPreferenceLoaded] = useState(false);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [dashboardAccess, setDashboardAccess] = useState(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage('');
    };

    const handleShowModal = () => {
        if (subscriptionStatus && preferences.length >= subscriptionStatus.maxPreferences) {
            alert('You have reached the maximum number of preferences allowed by your subscription.');
            return;
        }
        setShowModal(true);
    };

    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/preferences/dropdown-options`);
                setDropdownOptions(response.data);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        };

        fetchDropdownOptions();
    }, []);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Check if the subscription is inactive or expired
                if (response.data.status === 'Inactive' || response.data.expiresIn <= 0) {
                    setShowSubscriptionPrompt(true); // Show the re-subscribe prompt
                    setDashboardAccess(false); // Lock the dashboard access
                } else {
                    setSubscriptionStatus(response.data);
                    setShowSubscriptionPrompt(false); // Hide the prompt if the subscription is active
                    setDashboardAccess(true); // Allow dashboard access
                }
            } catch (error) {
                if (error.response && error.response.status === 400 && error.response.data === 'No subscription found.') {
                    setTimeout(() => {
                        setShowSubscriptionPrompt(true); // Show the subscription prompt if no active subscription
                        setSubscriptionStatus(null);
                    }, 500); // Delay to allow the dashboard to render fully
                } else {
                    console.error('Error fetching subscription status:', error);
                }
            } finally {
                setIsLoading(false); // Set loading to false after subscription status check
            }
        };

        fetchSubscriptionStatus();
    }, [navigate]);


    useEffect(() => {
        const fetchListings = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const preferencesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setPreferences(preferencesResponse.data);

                const matchedListingsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listings/matched-listings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setMatchedListings(matchedListingsResponse.data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchListings();
    }, [navigate]);

    const handleChange = (e) => {
        setNewPreference({
            ...newPreference,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        if (
            !newPreference.city ||
            !newPreference.priceRangeMin ||
            !newPreference.priceRangeMax ||
            !newPreference.propertyType ||
            !newPreference.distanceRadius ||
            !newPreference.minBedrooms ||
            !newPreference.maxBedrooms
        ) {
            setErrorMessage('Please fill in all fields before submitting.');
            return false;
        }
        setErrorMessage('');
        return true;
    };

    const handleEditPreference = (id) => {
        setIsEditing(true);
        setEditingPreferenceId(id);
        const preferenceToEdit = preferences.find(pref => pref.id === id);

        if (preferenceToEdit) {
            const updatedPreference = {
                city: preferenceToEdit.city || '',
                priceRangeMin: preferenceToEdit.priceRangeMin !== undefined ? preferenceToEdit.priceRangeMin.toString() : '',
                priceRangeMax: preferenceToEdit.priceRangeMax !== undefined ? preferenceToEdit.priceRangeMax.toString() : '',
                propertyType: preferenceToEdit.propertyType || '',
                distanceRadius: preferenceToEdit.distanceRadius !== undefined ? preferenceToEdit.distanceRadius.toString() : '',
                minBedrooms: preferenceToEdit.minBedrooms !== undefined ? preferenceToEdit.minBedrooms.toString() : '',
                maxBedrooms: preferenceToEdit.maxBedrooms !== undefined ? preferenceToEdit.maxBedrooms.toString() : ''
            };

            setNewPreference(updatedPreference);
            setPreferenceLoaded(true);
            setShowModal(true); // Ensure the modal is opened for editing
        }
    };


    const handleDeletePreference = async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const preferencesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreferences(preferencesResponse.data);
        } catch (error) {
            console.error('Error deleting preference:', error);
        }
    };

    const handleUpdatePreference = async () => {
        if (!validateForm()) return;

        const token = localStorage.getItem('token');

        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/update/${editingPreferenceId}`, newPreference, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsEditing(false);
            setEditingPreferenceId(null);
            setNewPreference({
                city: '',
                priceRangeMin: '',
                priceRangeMax: '',
                propertyType: '',
                distanceRadius: '',
                minBedrooms: '',
                maxBedrooms: ''
            });
            handleCloseModal();

            const preferencesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreferences(preferencesResponse.data);
        } catch (error) {
            console.error('Error updating preference:', error);
        }
    };

    const handleAddPreference = async () => {
        if (!subscriptionStatus || preferences.length >= subscriptionStatus.maxPreferences) {
            alert('You have reached the maximum number of preferences allowed by your subscription.');
            return;
        }
        if (!validateForm()) return;

        const token = localStorage.getItem('token');

        const parsedPriceRangeMin = newPreference.priceRangeMin.replace(/[^\d.]/g, '');
        const parsedPriceRangeMax = newPreference.priceRangeMax.replace(/[^\d.]/g, '');
        const parsedDistanceRadius = newPreference.distanceRadius.replace(/[^\d]/g, '');
        const parsedMinBedrooms = newPreference.minBedrooms === "Studio" ? 0 : parseInt(newPreference.minBedrooms);
        const parsedMaxBedrooms = newPreference.maxBedrooms === "Studio" ? 0 : parseInt(newPreference.maxBedrooms);

        try {
            const preferenceToSubmit = {
                ...newPreference,
                priceRangeMin: parsedPriceRangeMin ? parseFloat(parsedPriceRangeMin) : null,
                priceRangeMax: parsedPriceRangeMax ? parseFloat(parsedPriceRangeMax) : null,
                distanceRadius: parsedDistanceRadius ? parseInt(parsedDistanceRadius) : null,
                minBedrooms: parsedMinBedrooms,
                maxBedrooms: parsedMaxBedrooms
            };

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences/add`, preferenceToSubmit, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setNewPreference({
                city: '',
                priceRangeMin: '',
                priceRangeMax: '',
                propertyType: '',
                distanceRadius: '',
                minBedrooms: '',
                maxBedrooms: ''
            });

            handleCloseModal();

            const preferencesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listings/preferences`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPreferences(preferencesResponse.data);
        } catch (error) {
            console.error('Error adding preference:', error);
        }
    };

    // Pagination logic
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = matchedListings.slice(indexOfFirstListing, indexOfLastListing);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const renderAddPreferenceButton = () => {
        const isDisabled = !subscriptionStatus || preferences.length >= subscriptionStatus.maxPreferences;
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip>
                        {isDisabled ? 'Upgrade your subscription to add more preferences.' : 'Add a new preference'}
                    </Tooltip>
                }
            >
                <span className="d-inline-block">
                    <Button
                        variant="primary"
                        onClick={() => { setIsEditing(false); handleShowModal(); }}
                        disabled={isDisabled}
                        style={isDisabled ? { pointerEvents: 'none' } : {}}
                    >
                        Add New Preference
                    </Button>
                </span>
            </OverlayTrigger>
        );
    };

    // Add these functions above your return statement in the Dashboard component

    const handleStartFreeTrial = () => {
        // Logic to handle starting the free trial
        alert('Free trial started! You can now access Tier 3 features for a week.');
        // Redirect or trigger any other actions as needed
    };

    const handleSubscribe = (tier) => {
        // Logic to handle the subscription process based on the selected tier
        alert(`Subscribed to ${tier} plan successfully!`);
        // You can add any additional logic for processing the subscription or redirecting the user
    };

    const handlePausePreference = async (id, isActive) => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('User is not authenticated. Please log in.');
            return;
        }

        try {
            const updateDto = {
                id: id,
                isActive: isActive
            };

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/preferences/update-status`, updateDto, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            // Update the preferences list with the new state
            const updatedPreferences = preferences.map(pref =>
                pref.id === id ? { ...pref, isActive: isActive } : pref
            );
            setPreferences(updatedPreferences);
        } catch (error) {
            console.error('Error updating preference status:', error);
            alert('Failed to update preference status. Please try again.');
        }
    };

    return (
        <div className="dashboard-container">
            <Navbar/>
            {/* Main Dashboard Content */}
            <div className={`dashboard-box ${showSubscriptionPrompt ? 'dashboard-blurred' : ''}`}>
                {isLoading ? (
                    <div className="loading-spinner d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <>
                        <h2>Dashboard</h2>
                        {subscriptionStatus && (
                            <SubscriptionStatusCard
                                subscriptionStatus={subscriptionStatus}
                                preferences={preferences}/>
                        )}

                        {/* Call-to-Action for upgrading subscription */}
                        {subscriptionStatus && preferences.length >= subscriptionStatus.maxPreferences && (
                            <Alert variant="info" className="mt-3">
                                You've reached the maximum number of preferences for your current plan. Upgrade your
                                subscription to add more preferences and get more personalized property alerts!
                                <Button variant="link" className="p-0 ml-2" onClick={() => navigate('/upgrade')}>Upgrade
                                    Now</Button>
                            </Alert>
                        )}

                        {/* Preferences Display */}
                        <PreferencesTable
                            preferences={preferences}
                            handleEditPreference={handleEditPreference}
                            handleDeletePreference={handleDeletePreference}
                            handlePausePreference={handlePausePreference}
                        />


                        {/* Matched Listings Section */}
                        <MatchedListingsTable
                            matchedListings={matchedListings}
                            userTier={subscriptionStatus?.status}
                            preferences={preferences}
                        />

                        {/* Render Add Preference Button with Tooltip */}
                        {renderAddPreferenceButton()}

                        {/* Add or Edit Preference Modal */}
                        <AddOrEditPreferenceModal
                            showModal={showModal}
                            handleCloseModal={handleCloseModal}
                            handleChange={handleChange}
                            handleAddPreference={handleAddPreference}
                            handleUpdatePreference={handleUpdatePreference}
                            newPreference={newPreference}
                            dropdownOptions={dropdownOptions}
                            isEditing={isEditing}
                            preferenceLoaded={preferenceLoaded}
                        />
                    </>
                )}
            </div>

            {/* Overlay and Subscription Prompt */}
            {showSubscriptionPrompt && (
                <div className="subscription-prompt-overlay">
                    <div className="subscription-prompt-content">
                        <SubscriptionPrompt
                            onClose={() => navigate('/subscription')}
                            onGoToProfile={() => navigate('/profile')}
                            onStartFreeTrial={handleStartFreeTrial}
                            onSubscribe={handleSubscribe}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;