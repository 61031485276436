import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './NavMenu';
import SubscriptionHistory from './SubscriptionHistory';
import '../custom.css';
import { Modal, Button, Accordion, Toast } from 'react-bootstrap';

const Profile = () => {
    const [profile, setProfile] = useState({ email: '' });
    const [newEmail, setNewEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [subscription, setSubscription] = useState(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [isUpgrading, setIsUpgrading] = useState(false);
    const [newSubscriptionType, setNewSubscriptionType] = useState('');
    const [isFreeTrialDisabled, setIsFreeTrialDisabled] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);

    useEffect(() => {
        fetchProfileAndSubscription();
    }, []);

    const fetchProfileAndSubscription = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setMessage('User not logged in.');
            return;
        }

        try {
            const profileResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProfile(profileResponse.data);
            setNewEmail(profileResponse.data.email);

            const subscriptionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setSubscription(subscriptionResponse.data);
        } catch (error) {
            setMessage('Error fetching profile or subscription data.');
        }

        try {
            const plansResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/plans`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSubscriptionPlans(plansResponse.data);
        } catch (error) {
            setMessage('Error fetching subscription plans.');
        }
    };

    const handleEmailChange = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile/update`, { email: newEmail }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Email updated successfully!');
        } catch (error) {
            setMessage('Error updating email.');
        }
    };

    const handleChangePassword = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile/change-password`, { currentPassword, newPassword }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Password changed successfully!');
        } catch (error) {
            setMessage('Error changing password.');
        }
    };

    const handleUpgradeSubscription = async () => {
        const token = localStorage.getItem('token');
        if (!newSubscriptionType) {
            setMessage("Please select a subscription plan.");
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/upgrade`, { newSubscriptionType }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Subscription updated successfully!');
            setIsUpgrading(false);
            fetchProfileAndSubscription();
        } catch (error) {
            setMessage('Error updating subscription.');
        }
    };

    const handleFreeTrial = async () => {
        const token = localStorage.getItem('token');
        setIsFreeTrialDisabled(true);

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/upgrade`, {
                newSubscriptionType: 'Pro'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Free trial started for Pro!');
            fetchProfileAndSubscription();
        } catch (error) {
            setMessage('Error starting free trial.');
        }
    };

    const handleCancelSubscription = async (cancelType) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/cancel`, { cancelType }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage(`Subscription ${cancelType === 'immediate' ? 'cancelled immediately' : 'will be cancelled at the end of the billing period'}!`);
            fetchProfileAndSubscription();
        } catch (error) {
            setMessage('Error cancelling subscription.');
        } finally {
            setShowCancelModal(false);
        }
    };

    const isSubscriptionActive = subscription && (subscription.status === "Free" || subscription.status === "Basic" || subscription.status === "Pro");

    return (
        <div className="container-fluid px-3 profile-container">
            <Navbar />
            <div className="profile-box">
                <h2>Profile</h2>

                <div className="profile-section">
                    <h3>Update Email</h3>
                    <input type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} className="form-input" />
                    <Button variant="primary" onClick={handleEmailChange}>Update Email</Button>
                </div>

                <div className="profile-section">
                    <h3>Change Password</h3>
                    <input type="password" placeholder="Current Password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="form-input" />
                    <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-input" />
                    <Button variant="primary" onClick={handleChangePassword}>Change Password</Button>
                </div>

                <div className="profile-section">
                    <h3>Subscription</h3>
                    {subscription ? (
                        <div>
                            <p>Subscription Status: <span className={`subscription-status-badge ${subscription.status.toLowerCase()}`}>{subscription.status}</span></p>
                            {subscription.trialAvailable && (
                                <div>
                                    <p className="success-text">Free trial available!</p>
                                    <Button onClick={handleFreeTrial} disabled={isFreeTrialDisabled} variant="primary">Start Free Trial</Button>
                                </div>
                            )}
                            {subscription.expiresIn && <p>Expires in: {subscription.expiresIn} days</p>}

                            <div className="subscription-buttons d-flex justify-content-between">
                                <Button variant="primary" onClick={() => setIsUpgrading(true)} className="flex-grow-1 mr-2">Upgrade/Downgrade</Button>
                                <Button variant="outline-danger" onClick={() => setShowCancelModal(true)} disabled={!isSubscriptionActive} className="flex-grow-1">Cancel Subscription</Button>
                            </div>

                            {isUpgrading && (
                                <div>
                                    <h4>Select a new subscription plan</h4>
                                    <select value={newSubscriptionType} onChange={(e) => setNewSubscriptionType(e.target.value)} className="form-input">
                                        <option value="">Select Subscription</option>
                                        {subscriptionPlans.filter(plan => plan.name !== subscription.status).map(plan => (
                                            <option key={plan.id} value={plan.name}>
                                                {plan.name} - ${plan.price}
                                            </option>
                                        ))}
                                    </select>
                                    <Button onClick={handleUpgradeSubscription} variant="primary">Confirm Upgrade</Button>
                                    <Button onClick={() => setIsUpgrading(false)} variant="outline-secondary">Cancel</Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No active subscription.</p>
                            <div className="subscription-options">
                                <h4>Select a Subscription Plan</h4>
                                <select value={newSubscriptionType} onChange={(e) => setNewSubscriptionType(e.target.value)} className="form-input">
                                    <option value="">Select Subscription</option>
                                    {subscriptionPlans.map(plan => (
                                        <option key={plan.id} value={plan.name}>
                                            {plan.name} - ${plan.price}
                                        </option>
                                    ))}
                                </select>
                                <Button onClick={handleUpgradeSubscription} variant="primary">Confirm Upgrade</Button>
                            </div>
                            {subscriptionPlans.find(plan => plan.name === 'Free Trial') && (
                                <Button onClick={handleFreeTrial} disabled={isFreeTrialDisabled} variant="primary">Start Free Trial</Button>
                            )}
                        </div>
                    )}
                </div>

                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>View Subscription History</Accordion.Header>
                        <Accordion.Body>
                            <SubscriptionHistory />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                {message && (
                    <Toast onClose={() => setMessage('')} show={!!message} delay={3000} autohide>
                        <Toast.Body>{message}</Toast.Body>
                    </Toast>
                )}

                <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to cancel your subscription?</p>
                        <p>You have the following options:</p>
                        <ul>
                            <li><strong>Keep Subscription:</strong> Continue enjoying your current plan without any changes.</li>
                            <li><strong>Cancel Now:</strong> Immediately cancel your subscription, and you'll lose access to the benefits.</li>
                            <li><strong>Cancel When Ends:</strong> Your subscription will be active until the end of the current billing cycle, after which it will automatically cancel.</li>
                        </ul>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant="primary" onClick={() => setShowCancelModal(false)} style={{ minWidth: '150px' }}>Keep Subscription</Button>
                        <Button variant="outline-secondary" onClick={() => handleCancelSubscription('immediate')} style={{ minWidth: '150px' }}>Cancel Now</Button>
                        <Button variant="outline-secondary" onClick={() => handleCancelSubscription('end_of_cycle')} style={{ minWidth: '150px' }}>Cancel When Ends</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Profile;
