import React from 'react';
import { Card, ProgressBar } from 'react-bootstrap';

const SubscriptionStatusCard = ({ subscriptionStatus, preferences }) => {
    // Calculate the display value for notification frequency
    const notificationFrequencyDisplay =
        subscriptionStatus.status === 'Free' && subscriptionStatus.notificationDelayMinutes === 1440
            ? '24 hours'
            : `Every ${subscriptionStatus.notificationDelayMinutes} minutes`;

    return (
        <Card className="mb-4 shadow-sm">
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-primary mb-0">
                        <i className="bi bi-card-checklist"></i> Subscription Status: {subscriptionStatus.status}
                    </h5>
                    {subscriptionStatus.expiresIn && (
                        <span className="text-muted">Expires in: {subscriptionStatus.expiresIn} days</span>
                    )}
                </div>
                <hr/>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div className="d-flex align-items-center">
                        <p className="mb-0 mr-4"><strong>Preferences Limit:</strong> {subscriptionStatus.maxPreferences}</p>
                        <p className="mb-0"><strong>Notification Frequency:</strong> {notificationFrequencyDisplay}</p>
                    </div>
                </div>
                <div className="w-100 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0"><strong>Preferences Usage:</strong></p>
                        <div className="flex-grow-1 ml-3">
                            <ProgressBar
                                now={(preferences.length / subscriptionStatus.maxPreferences) * 100}
                                label={`${preferences.length} / ${subscriptionStatus.maxPreferences}`}/>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default SubscriptionStatusCard;
