import React from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const AddOrEditPreferenceModal = ({
                                      showModal,
                                      handleCloseModal,
                                      isEditing,
                                      errorMessage,
                                      newPreference,
                                      handleChange,
                                      handleAddPreference,
                                      handleUpdatePreference,
                                      dropdownOptions
                                  }) => (
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit Preference' : 'Add New Preference'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form>
                <Form.Group controlId="formCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        name="city"
                        value={newPreference.city || ''}
                        onChange={handleChange}
                        placeholder="City"
                    />
                </Form.Group>

                <Form.Group controlId="formPriceRangeMin">
                    <Form.Label>Min Price</Form.Label>
                    <Form.Control
                        as="select"
                        name="priceRangeMin"
                        value={newPreference.priceRangeMin || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Min Price</option>
                        {dropdownOptions.PriceRange.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formPriceRangeMax">
                    <Form.Label>Max Price</Form.Label>
                    <Form.Control
                        as="select"
                        name="priceRangeMax"
                        value={newPreference.priceRangeMax || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Max Price</option>
                        {dropdownOptions.PriceRange.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formPropertyType">
                    <Form.Label>Property Type</Form.Label>
                    <Form.Control
                        as="select"
                        name="propertyType"
                        value={newPreference.propertyType || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Property Type</option>
                        {dropdownOptions.PropertyType.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formDistanceRadius">
                    <Form.Label>Distance Radius</Form.Label>
                    <Form.Control
                        as="select"
                        name="distanceRadius"
                        value={newPreference.distanceRadius || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Distance Radius</option>
                        {dropdownOptions.DistanceRadius.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formMinBedrooms">
                    <Form.Label>Min Bedrooms</Form.Label>
                    <Form.Control
                        as="select"
                        name="minBedrooms"
                        value={newPreference.minBedrooms || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Min Bedrooms</option>
                        {dropdownOptions.Bedrooms.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formMaxBedrooms">
                    <Form.Label>Max Bedrooms</Form.Label>
                    <Form.Control
                        as="select"
                        name="maxBedrooms"
                        value={newPreference.maxBedrooms || ''}
                        onChange={handleChange}
                    >
                        <option value="">Please select Max Bedrooms</option>
                        {dropdownOptions.Bedrooms.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            <Button variant="primary"
                    onClick={isEditing ? handleUpdatePreference : handleAddPreference}>
                {isEditing ? 'Update Preference' : 'Add Preference'}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default AddOrEditPreferenceModal;
