import React from 'react';
import { Table, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa'; // Import an icon for the status indicator

const PreferencesTable = ({ preferences, handleEditPreference, handleDeletePreference, handlePausePreference }) => {
    return (
        <div>
            <h3>Your Preferences</h3>
            <Table striped bordered hover responsive>
                <thead>
                <tr>
                    <th>Status</th>
                    <th>City</th>
                    <th>Price Range</th>
                    <th>Property Type</th>
                    <th>Distance Radius</th>
                    <th>Bedrooms</th>
                    <th>Listings Matched</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {preferences.map(pref => (
                    <tr key={pref.id}>
                        <td className="text-center"> {/* Centering the icon */}
                            <FaCircle color={pref.isActive ? 'green' : 'red'} />
                        </td>
                        <td>{pref.city}</td>
                        <td>{`${pref.priceRangeMin} - ${pref.priceRangeMax}`}</td>
                        <td>{pref.propertyType}</td>
                        <td>{`${pref.distanceRadius} km`}</td>
                        <td>{`${pref.minBedrooms} - ${pref.maxBedrooms}`}</td>
                        <td>{pref.listingsMatched}</td>
                        <td className="actions-column">
                            <DropdownButton
                                id={`dropdown-basic-button-${pref.id}`}
                                title="Actions"
                                variant="secondary"
                                size="sm"
                            >
                                <Dropdown.Item onClick={() => handleEditPreference(pref.id)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={() => handlePausePreference(pref.id, !pref.isActive)}>
                                    {pref.isActive ? 'Pause' : 'Resume'}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleDeletePreference(pref.id)} className="text-danger">
                                    Delete
                                </Dropdown.Item>
                            </DropdownButton>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    );
};

export default PreferencesTable;
