import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './NavMenu';
import '../custom.css'; // Import your custom CSS

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [emailError, setEmailError] = useState(null);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [passwordMatchError, setPasswordMatchError] = useState(null);

    const navigate = useNavigate();

    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate password strength
    const validatePassword = (password) => {
        const requirements = [];
        if (password.length < 8) requirements.push("Password must be at least 8 characters long.");
        if (!/[A-Z]/.test(password)) requirements.push("Password must include at least one uppercase letter.");
        if (!/[0-9]/.test(password)) requirements.push("Password must include at least one number.");
        return requirements;
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Live validation for email
        if (!emailRegex.test(emailValue)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError(null);
        }
    };

    const handlePasswordChange = (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);

        // Live validation for password
        const errors = validatePassword(passwordValue);
        setPasswordErrors(errors);
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPasswordValue = e.target.value;
        setConfirmPassword(confirmPasswordValue);

        // Live validation for password match
        if (confirmPasswordValue !== password) {
            setPasswordMatchError("Passwords do not match.");
        } else {
            setPasswordMatchError(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform final validation
        if (emailError || passwordErrors.length > 0 || passwordMatchError) {
            setErrors(["Please correct the errors before submitting."]);
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/Auth/register`, {
                email,
                password,
            });
            
            console.log('Registration successful', response.data);
            navigate('/login');
        } catch (err) {
            // Handle server errors
            if (err.response && err.response.data) {
                const apiErrors = err.response.data.errors || err.response.data;
                if (Array.isArray(apiErrors)) {
                    const errorMessages = apiErrors.map(error => error.description);
                    setErrors(errorMessages);
                } else if (typeof apiErrors === 'object') {
                    setErrors([apiErrors.description || "Registration failed."]);
                }
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    };

    return (
        <div className="register-container">
            <Navbar />
            <div className="register-box">
                <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className="form-input"
                        />
                        {/* Show email validation error */}
                        {emailError && <div className="validation-error">{emailError}</div>}
                    </div>
                    <div className="input-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            className="form-input"
                        />
                        {/* Show password validation errors */}
                        {passwordErrors.length > 0 && (
                            <div className="validation-error">
                                <ul>
                                    {passwordErrors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="input-group">
                        <label>Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                            className="form-input"
                        />
                        {/* Show password match validation error */}
                        {passwordMatchError && <div className="validation-error">{passwordMatchError}</div>}
                    </div>
                    <button type="submit" className="btn-primary">Register</button>
                </form>

                {/* Display general error messages */}
                {errors.length > 0 && (
                    <div className="error-message">
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Register;
