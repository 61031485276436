import React from 'react';
import { Card, Button } from 'react-bootstrap';

const SubscriptionPrompt = ({ onClose, onGoToProfile, onStartFreeTrial, onSubscribe }) => {
    return (
        <div className="subscription-prompt-overlay">
            <div className="subscription-prompt-content">
                <h2>Oops! You don't have an active subscription</h2>
                <p>To access the full features of the dashboard, please subscribe to one of our plans below.</p>

                <div className="subscription-plans">
                    <Card className="subscription-plan-card wider-card">
                        <Card.Body>
                            <h3 className="text-primary">Free Tier</h3>
                            <p><strong>Price:</strong> £0/month</p>
                            <ul>
                                <li>⚡ 2 notifications per day</li>
                                <li>🔖 1 saved preference</li>
                                <li>⏱️ Notifications within 2 hours</li>
                                <li>💬 Basic customer support</li>
                                <li>🔍 Access to standard property listings</li>
                            </ul>
                            <Button variant="outline-primary" className="mt-3 align-button" onClick={() => onSubscribe('free')}>Subscribe</Button>
                        </Card.Body>
                    </Card>

                    <Card className="subscription-plan-card wider-card">
                        <Card.Body>
                            <h3 className="text-primary">Tier 2</h3>
                            <p><strong>Price:</strong> £2.99/month</p>
                            <ul>
                                <li>⚡ 10 notifications per day</li>
                                <li>🔖 3 saved preferences</li>
                                <li>⏱️ Notifications within 1 hour</li>
                                <li>🤝 Priority customer support</li>
                                <li>🔍 Access to premium property listings</li>
                            </ul>
                            <Button variant="outline-primary" className="mt-3 align-button" onClick={() => onSubscribe('tier2')}>Subscribe</Button>
                        </Card.Body>
                    </Card>

                    <Card className="subscription-plan-card wider-card">
                        <Card.Body>
                            <h3 className="text-primary">Tier 3</h3>
                            <p><strong>Price:</strong> £5.99/month</p>
                            <ul>
                                <li>⚡ Unlimited notifications per day</li>
                                <li>🔖 10 saved preferences</li>
                                <li>⏱️ Notifications within 10 minutes</li>
                                <li>🤝 Dedicated customer support</li>
                                <li>🌟 Early access to exclusive listings</li>
                            </ul>
                            <Button variant="outline-primary" className="mt-3 align-button"
                                    onClick={() => onSubscribe('tier3')}>Subscribe</Button>
                            <Button variant="link" className="start-free-trial-btn" onClick={onStartFreeTrial}>
                                <em>Start Free Trial</em>
                            </Button>
                        </Card.Body>
                    </Card>
                </div>

                <div className="subscription-cta-buttons">
                    <Button variant="warning" onClick={onGoToProfile} className="mt-3 wider-button">Go to Profile</Button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPrompt;
