import React, { useState } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import CustomPagination from './CustomPagination';
import '../custom.css';

const MatchedListingsTable = ({ matchedListings, userTier, preferences }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const listingsPerPage = 15; // Number of listings to show per page

    // Check user tier and handle listings accordingly
    let allListings;
    if (userTier === 'Free') {
        // Limit to first 5 listings and show dummy rows for Free users
        const maxDummyRows = listingsPerPage - 5;
        allListings = [
            ...matchedListings.slice(0, 5),
            ...Array(maxDummyRows).fill(null),
        ];
    } else {
        // For Basic and Pro users, include all available listings without dummy rows
        allListings = matchedListings;
    }

    // Calculate listings for the current page
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = allListings.slice(indexOfFirstListing, indexOfLastListing);

    const totalListingsCount = preferences.reduce((acc, pref) => acc + (pref.listingsMatched || 0), 0);
    const remainingListingsCount = totalListingsCount - matchedListings.length;
    const totalPageCount = Math.ceil(matchedListings.length / listingsPerPage);

    // Function to handle page change
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="matched-listings-container position-relative">
            <h3>Matched Listings</h3>
            {userTier === 'Basic' && (
                <Alert variant="info" className="mt-3 mb-4 text-center">
                    <p>You have {remainingListingsCount} more matching listings!</p>
                    <p>Upgrade to Pro to see all the newest listings.</p>
                    <Button variant="primary" onClick={() => window.location.href = '/subscription'}>
                        Upgrade Now
                    </Button>
                </Alert>
            )}
            <Table striped bordered hover responsive className="mb-4">
                <thead>
                <tr>
                    <th>City</th>
                    <th>Property Type</th>
                    <th>Price</th>
                    <th>Actual Bedrooms</th>
                    <th>Listing URL</th>
                    <th>Date Added</th>
                    <th>Date Notification Sent</th>
                </tr>
                </thead>
                <tbody>
                {currentListings.map((listing, index) =>
                    listing ? (
                        <tr key={listing.id}>
                            <td>{listing.city}</td>
                            <td>{listing.propertyType}</td>
                            <td>{listing.price}</td>
                            <td>{listing.actualBedrooms}</td>
                            <td>
                                <a href={listing.listingUrl} target="_blank" rel="noopener noreferrer">View Listing</a>
                            </td>
                            <td>{new Date(listing.dateAdded).toLocaleDateString()}</td>
                            <td>{listing.dateNotificationSent ? new Date(listing.dateNotificationSent).toLocaleDateString() : 'Not Sent'}</td>
                        </tr>
                    ) : (
                        <tr key={`dummy-${index}`} className="blurred-row">
                            <td className="blurred-content">Upgrade to see this listing</td>
                            <td className="blurred-content">Upgrade to see this listing</td>
                            <td className="blurred-content">1500</td>
                            <td className="blurred-content">3</td>
                            <td className="blurred-content">View Listing</td>
                            <td className="blurred-content">10/10/2020</td>
                            <td className="blurred-content">Not Sent</td>
                        </tr>
                    )
                )}
                {userTier === 'Free' && (
                    <tr className="blurred-overlay">
                        <td colSpan="7">
                            <div className="upgrade-message">
                                <p>You have {remainingListingsCount} more matching listings! Upgrade to Basic to see today's listings or upgrade to Pro to see all the newest listings.</p>
                                <Button variant="primary" onClick={() => window.location.href = '/subscription'}>Upgrade Now</Button>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>


            {/* Use CustomPagination component for better pagination controls */}
            <CustomPagination
                currentPage={currentPage}
                totalPageCount={totalPageCount}
                onPageChange={handlePageClick}
                userTier={userTier}
            />
        </div>
    );
};

export default MatchedListingsTable;
