import React, { useEffect, useState } from 'react';

const SubscriptionHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/history`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch subscription history');
        }

        const data = await response.json();
        setHistory(data);
      } catch (error) {
        console.error('Error fetching subscription history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionHistory();
  }, []);

  if (loading) {
    return <div>Loading subscription history...</div>;
  }

  if (history.length === 0) {
    return <div>No subscription history found.</div>;
  }

  return (
    <div>
      <h3>Subscription History</h3>
      <ul>
        {history.map((item) => (
          <li key={item.id}>
            <strong>{item.subscriptionTier}</strong> - 
            {new Date(item.startDate).toLocaleDateString()} to{' '}
            {item.endDate ? new Date(item.endDate).toLocaleDateString() : 'Ongoing'}
            {' '}(<em>Status: {item.status}</em>)
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionHistory;
