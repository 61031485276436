import React from 'react';
import Navbar from './NavMenu';
import '../custom.css'; // Ensure this CSS file includes custom styles as needed.
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
    return (
        <div className="main-container">
            <Navbar />

            {/* Hero Section with Registration Form */}
            <header
                className="hero-section d-flex justify-content-center align-items-center text-center py-5"
                style={{ backgroundImage: 'url(/path/to/your/background-image.jpg)', backgroundSize: 'cover', color: '#fff' }}
            >
                <div className="overlay p-5" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}>
                    <h1 className="display-4 fw-bold mb-4">Property Notifier</h1>
                    <p className="lead mb-4">Sign up to receive notifications for the best property listings matching your preferences!</p>

                    {/* Registration Form */}
                    <form action="/register" className="registration-form p-3 bg-light rounded shadow-sm">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Username"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Password"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Sign Up</button>
                    </form>
                </div>
            </header>

            {/* How It Works Section */}
            <section className="how-it-works-section py-5">
                <div className="container">
                    <h2 className="text-center mb-4">How It Works</h2>
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="card p-3 border-0 shadow rounded">
                                <h3 className="mb-3">1. Set Your Preferences</h3>
                                <p>Choose your preferred city, property type, price range, and notification settings to find your ideal property matches quickly and easily.</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card p-3 border-0 shadow rounded">
                                <h3 className="mb-3">2. Receive Notifications</h3>
                                <p>Once a property matches your criteria, you'll receive notifications. Stay informed with real-time updates on listings that meet your needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="card p-3 border-0 shadow rounded">
                                <h3 className="mb-3">3. Stay Updated</h3>
                                <p>Use your dashboard to view matched listings, update preferences, and manage your subscription seamlessly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Subscription Tiers Section */}
            <section className="subscription-tiers-section py-5 bg-light">
                <div className="container">
                    <h2 className="text-center mb-4">Our Subscription Plans</h2>
                    <p className="text-center mb-5">We offer flexible subscription options to suit your needs. Whether you're casually browsing or actively hunting for properties, we've got you covered.</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-3 border-0 shadow-sm rounded text-center">
                                <h3 className="text-primary">Free Tier</h3>
                                <p><strong>Price:</strong> £0/month</p>
                                <ul className="list-unstyled">
                                    <li>2 notifications per day</li>
                                    <li>1 saved preference</li>
                                    <li>Notifications within 2 hours</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3 border-0 shadow-sm rounded text-center">
                                <h3 className="text-primary">Tier 2</h3>
                                <p><strong>Price:</strong> £2.99/month</p>
                                <ul className="list-unstyled">
                                    <li>10 notifications per day</li>
                                    <li>3 saved preferences</li>
                                    <li>Notifications within 1 hour</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3 border-0 shadow-sm rounded text-center">
                                <h3 className="text-primary">Tier 3</h3>
                                <p><strong>Price:</strong> £5.99/month</p>
                                <ul className="list-unstyled">
                                    <li>Unlimited notifications per day</li>
                                    <li>10 saved preferences</li>
                                    <li>Notifications within 10 minutes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button className="btn btn-primary btn-lg rounded-pill" onClick={() => window.location.href = '/register'}>Start Free Trial</button>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="footer-section bg-dark text-white py-3 text-center">
                <p className="mb-0">© 2024 Property Notifier. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Home;
