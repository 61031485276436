// src/components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../custom.css'; // Import custom CSS for additional styling

const Navbar = () => {
  const navigate = useNavigate();

  // Check if user is logged in by looking for the token
  const isLoggedIn = !!localStorage.getItem('token');

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/'); // Redirect to home page after logout
  };

  return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm py-3">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src="/images.png" alt="Logo" style={{ height: '40px' }} />
            <span className="ms-2 fw-bold">Property Notifier</span>
          </Link>

          {/* Toggle Button for Mobile View */}
          <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/dashboard">
                  Dashboard
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/profile">
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/subscription">
                  Subscription
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/features">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark fw-semibold" to="/pricing">
                  Pricing
                </Link>
              </li>
              {isLoggedIn ? (
                  <li className="nav-item">
                    <button
                        className="btn btn-outline-primary rounded-pill ms-2"
                        onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </li>
              ) : (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link text-dark fw-semibold" to="/login">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link text-dark fw-semibold" to="/register">
                        Register
                      </Link>
                    </li>
                  </>
              )}
            </ul>
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
